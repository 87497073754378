<template>
  <div v-if="listData.length">
    <div class="targetingItem">
      <p class="baseName">
        <span
          class="lebel"
          style="flex: 90px 0 0"
        ></span>
        <span>
          <el-checkbox v-model="checked">是否自动命名</el-checkbox>
        </span>
        <!-- <el-button @click="nameConfig" size="mini" style="margin-left:15px">自动命名设置</el-button> -->
      </p>
    </div>
    <div class="targetingItem">
      <p
        class="baseName"
        v-if="!isNotMix(nameList)"
      >
        <span class="lebel">名称：</span>
        <span>
          <el-button
            type="text"
            size="mini"
            @click="nameMixShow = true"
            >混合值查看及编辑</el-button
          >
        </span>
      </p>
      <el-row v-if="isNotMix(nameList)">
        <el-col :span="20">
          <name
            :label-width="labelWidth"
            :nameInfo="nameMsg"
            @getNameMsg="getNameMsg"
          ></name>
        </el-col>
        <el-col
          :span="2"
          v-if="currentSelect.length > 1"
          ><el-button
            type="text"
            size="mini"
            @click="nameMixShow = true"
            class="mixBtn"
            >混合值查看及编辑</el-button
          ></el-col
        >
      </el-row>
    </div>
    <div class="targetingItem">
      <p
        class="baseName"
        v-if="!isNotMix(statusList)"
      >
        <span class="lebel">状态：</span>
        <span>
          <el-button
            type="text"
            size="mini"
            @click="statusMixShow = true"
            >混合值查看及编辑</el-button
          >
        </span>
      </p>
      <el-row v-if="isNotMix(statusList)">
        <el-col :span="20">
          <status
            :label-width="labelWidth"
            :statusInfo="statusMsg"
            @getStatusMsg="getStatusMsg"
          ></status>
        </el-col>
        <el-col
          :span="2"
          v-if="currentSelect.length > 1"
          ><el-button
            type="text"
            size="mini"
            @click="statusMixShow = true"
            class="mixBtn"
            >混合值查看及编辑</el-button
          ></el-col
        >
      </el-row>
    </div>
    <div
      class="targetingItem"
      v-if="copy"
    >
      <p
        class="baseName"
        v-if="!isNotMix(pixelList) && !pixelMixShow"
      >
        <span class="lebel">像素：</span>
        <span>
          <el-button
            type="text"
            size="mini"
            @click="editMix('pixelMixShow')"
            >混合值查看及编辑</el-button
          >
        </span>
      </p>
      <el-row v-if="isNotMix(pixelList) || pixelMixShow">
        <el-col :span="20">
          <pixel
            :label-width="labelWidth"
            :pixelMsg="pixelMsg"
            @getPixelMsg="getPixelMsg"
            :accountPixelList="accountPixelList"
            @getPixels="getPixelList"
            :loadPixel="loadPixel"
          ></pixel>
        </el-col>
        <el-col
          :span="2"
          v-if="currentSelect.length > 1"
          ><el-button
            type="text"
            size="mini"
            @click="setBase('pixel')"
            class="mixBtn"
            >恢复初始值</el-button
          ></el-col
        >
      </el-row>
    </div>
    <div class="targetingItem">
      <p
        class="baseName"
        v-if="!isNotMix(locationList) && !editlocations"
      >
        <span class="lebel">地区：</span>
        <span>
          <el-tooltip
            class="item"
            effect="dark"
            content="地区编辑后将会统一"
            placement="top-start"
          >
            <el-button
              type="text"
              size="mini"
              @click="editMix('editlocations')"
              >混合值编辑</el-button
            >
          </el-tooltip>
          <!-- <el-button type="text" size="mini">恢复</el-button> -->
        </span>
      </p>
      <el-row v-if="locationList.length && (isNotMix(locationList) || editlocations)">
        <el-col :span="20">
          <location
            :label-width="labelWidth"
            :locationMsg="locationMsg"
            @editBaseList="getLocations"
            :account="account"
            :targetUserId="targetUserId"
          ></location>
        </el-col>
        <el-col
          :span="2"
          v-if="currentSelect.length > 1"
          ><el-button
            type="text"
            size="mini"
            @click="setBase('locations')"
            class="mixBtn"
            >恢复初始值</el-button
          ></el-col
        >
      </el-row>
    </div>
    <div class="targetingItem">
      <p
        class="baseName"
        v-if="!isNotMix(localsList) || !localsList.length"
      >
        <span class="lebel">语言：</span>
        <span>
          <el-button
            type="text"
            size="mini"
            @click="localsMixShow = true"
            >混合值查看及编辑</el-button
          >
          <!-- <el-button type="text" size="mini">恢复</el-button> -->
        </span>
      </p>
      <el-row v-if="isNotMix(localsList) || localsList.length == 0">
        <el-col :span="20">
          <locals
            :label-width="labelWidth"
            v-if="isNotMix(localsList)"
            :localsMsg="localsMsg"
            @setLocals="getLocals"
          ></locals>
        </el-col>
        <el-col
          :span="2"
          v-if="currentSelect.length > 1"
          ><el-button
            type="text"
            size="mini"
            @click="localsMixShow = true"
            class="mixBtn"
            >混合值查看及编辑</el-button
          ></el-col
        >
      </el-row>
    </div>
    <div class="targetingItem">
      <p
        class="baseName"
        v-if="!isNotMix(sexList)"
      >
        <span class="lebel">性别：</span>
        <span>
          <el-button
            type="text"
            size="mini"
            @click="sexMixShow = true"
            >混合值查看及编辑</el-button
          >
          <!-- <el-button type="text" size="mini">恢复</el-button> -->
        </span>
      </p>
      <el-row v-if="isNotMix(sexList)">
        <el-col :span="8">
          <sex
            :label-width="labelWidth"
            :sexMsg="sexMsg"
            @getSex="getSex"
          ></sex>
        </el-col>
        <el-col
          :span="2"
          v-if="currentSelect.length > 1"
          ><el-button
            type="text"
            size="mini"
            @click="sexMixShow = true"
            class="mixBtn"
            >混合值查看及编辑</el-button
          ></el-col
        >
      </el-row>
    </div>
    <div class="targetingItem">
      <p
        class="baseName"
        v-if="!isNotMix(ageList)"
      >
        <span class="lebel">年龄：</span>
        <span>
          <el-button
            type="text"
            size="mini"
            @click="ageMixShow = true"
            >混合值查看及编辑</el-button
          >
          <!-- <el-button type="text" size="mini">恢复</el-button> -->
        </span>
      </p>
      <el-row v-if="isNotMix(ageList)">
        <el-col :span="20">
          <age
            :label-width="labelWidth"
            :ageMsg="ageMsg"
            @getAge="getAge"
            :locationList="locationList"
          ></age>
        </el-col>
        <el-col
          :span="2"
          v-if="currentSelect.length > 1"
          ><el-button
            type="text"
            size="mini"
            @click="ageMixShow = true"
            class="mixBtn"
            >混合值查看及编辑</el-button
          ></el-col
        >
      </el-row>
    </div>
    <!-- {{placementMsg}} -->
    <div
      class="targetingItem"
      v-if="currentSelect.length == 1"
    >
      <placement
        :initData="initData"
        @getPlacemment="getPlacemment"
        :placement="placementMsg"
      ></placement>
    </div>
    <!-- 进阶受众 -->
    <div
      class="targetingItem"
      v-if="currentSelect.length == 1"
    >
      <advanced-audience v-model="advancedMsg"></advanced-audience>
    </div>
    <div class="targetingItem">
      <p
        class="baseName"
        v-if="!isNotMix(flexibleSpecList) && !flexibleSpecShow"
      >
        <span class="lebel">受众设置：</span>
        <span>
          <el-tooltip
            class="item"
            effect="dark"
            content="受众编辑后将会统一"
            placement="top-start"
          >
            <el-button
              type="text"
              size="mini"
              @click="setFelx"
              >混合值编辑</el-button
            >
          </el-tooltip>
          <!-- <el-button type="text" size="mini">恢复</el-button> -->
        </span>
      </p>
      <el-row v-if="isNotMix(flexibleSpecList) || flexibleSpecShow">
        <el-col :span="20">
          <flexible-spec
            :label-width="labelWidth"
            :flexibleSpecMsg="flexibleSpecMsg"
            :curreent-select="currentSelect"
            @setFexible="setFexible"
            :targetUserId="targetUserId"
            :invaild_insterest="statusData ? statusData.invaild_insterest : []"
          ></flexible-spec>
        </el-col>
        <el-col
          :span="2"
          v-if="currentSelect.length > 1"
          ><el-button
            type="text"
            size="mini"
            @click="setBase('flexible')"
            class="mixBtn"
            >恢复初始值</el-button
          ></el-col
        >
      </el-row>
      <!-- <flexible-spec :label-width="labelWidth" v-if="isNotMix(flexibleSpecList) || (flexibleSpecShow)" :flexibleSpecMsg="flexibleSpecMsg" @setFexible="setFexible"></flexible-spec> -->
    </div>
    <div class="targetingItem">
      <p
        class="baseName"
        v-if="!isNotMix(relationsList) && !relationsShow"
      >
        <span class="lebel">关系：</span>
        <span>
          <el-tooltip
            class="item"
            effect="dark"
            content="关系编辑后将会统一"
            placement="top-start"
          >
            <el-button
              type="text"
              size="mini"
              @click="relationsShow = true"
              >混合值编辑</el-button
            >
          </el-tooltip>
          <!-- <el-button type="text" size="mini">恢复</el-button> -->
        </span>
      </p>
      <el-row v-if="isNotMix(relationsList) || relationsShow">
        <el-col :span="20">
          <connection
            :label-width="labelWidth"
            :relationsMsg="relationsMsg"
            @setReationsMsg="setReationsMsg"
            :pageList="pageList"
          ></connection>
        </el-col>
        <el-col
          :span="2"
          v-if="currentSelect.length > 1"
          ><el-button
            type="text"
            size="mini"
            @click="setBase('relations')"
            class="mixBtn"
            >恢复初始值</el-button
          ></el-col
        >
      </el-row>
    </div>
    <div class="targetingItem">
      <p
        class="baseName"
        v-if="!isNotMix(scheduleList)"
      >
        <span class="lebel">排期：</span>
        <span>
          <el-button
            type="text"
            size="mini"
            @click="scheduleMixShow = true"
            >混合值查看及编辑</el-button
          >
          <!-- <el-button type="text" size="mini">恢复</el-button> -->
        </span>
      </p>
      <el-row v-if="isNotMix(scheduleList)">
        <el-col :span="18">
          <schedule
            :label-width="labelWidth"
            :scheduleMsg="scheduleMsg"
            @getSchedule="getSchedule"
            :copy="copy"
          ></schedule>
        </el-col>
        <el-col
          :span="2"
          v-if="currentSelect.length > 1"
          ><el-button
            type="text"
            size="mini"
            @click="scheduleMixShow = true"
            class="mixBtn"
            >混合值查看及编辑</el-button
          ></el-col
        >
      </el-row>
    </div>
    <div class="targetingItem">
      <p
        class="baseName"
        v-if="!isNotMix(budgetList)"
      >
        <span class="lebel">预算：</span>
        <span>
          <el-button
            type="text"
            size="mini"
            @click="budgetMixShow = true"
            >混合值查看及编辑</el-button
          >
        </span>
      </p>
      <el-row v-if="isNotMix(budgetList)">
        <el-col :span="20">
          <budget
            :label-width="labelWidth"
            :budgetMsg="budgetMsg"
            @getBudgetMsg="getBudgetMsg"
            level="adset"
            @setBudget="setBudget"
            :params="params"
          ></budget>
        </el-col>
        <el-col
          :span="2"
          v-if="currentSelect.length > 1"
          ><el-button
            type="text"
            size="mini"
            @click="budgetMixShow = true"
            class="mixBtn"
            >混合值查看及编辑</el-button
          ></el-col
        >
      </el-row>
    </div>
    <!-- 竞价 -->
    <div class="targetingItem">
      <p
        class="baseName"
        v-if="!isNotMix(bidAmountList) && !bidAmountShow"
      >
        <span class="lebel">竞价：</span>
        <span>
          <el-tooltip
            class="item"
            effect="dark"
            content="竞价编辑后将会统一(选择的广告组既有广告组预算又有广告系列预算则不能编辑)"
            placement="top-start"
          >
            <el-button
              type="text"
              size="mini"
              @click="bidAmountShow = true"
              :disabled="!isNotMix(cboList) || !isNotMix(bidStrategyList)"
              >混合值编辑</el-button
            >
          </el-tooltip>
          <span
            class="tips"
            style="margin-left: 10px"
            v-if="!isNotMix(cboList) || !isNotMix(bidStrategyList)"
            >(选择的广告组既有广告组预算又有广告系列预算则不能编辑混合值)(选择的广告组竞价策略或者优化目标或者广告类型不一致则不能混合编辑)</span
          >
        </span>
      </p>
      <el-row v-if="isNotMix(bidAmountList) || bidAmountShow">
        <el-col :span="20">
          <bid-amount
            :label-width="labelWidth"
            :bidAmountMsg="bidAmountMsg"
            @getBidAmountMsg="getBidAmountMsg"
            level="adset"
            @setBid="setBid"
            @setRoas="setRoas"
            :copy="copy"
            :params="params"
          ></bid-amount>
        </el-col>
        <el-col
          :span="2"
          v-if="currentSelect.length > 1"
          ><el-button
            type="text"
            size="mini"
            @click="setBase('bidAmount')"
            class="mixBtn"
            >恢复初始值</el-button
          ></el-col
        >
      </el-row>
    </div>
    <!-- 欧盟输入框 -->
    <div class="targetingItem">
      <el-row>
        <el-col :span="20">
          <europe-input
            :label-width="labelWidth"
            :bidEuropeMsg="bidEuropeMsg"
            @getBidEuropeMsg="getBidEuropeMsg"
            level="adset"
          ></europe-input>
        </el-col>
      </el-row>
    </div>
    <!-- <div class="targetingItem">
            <p class="baseName">
                <span class="lebel">营销目标：</span>
                <span>
                    <el-tooltip class="item" effect="dark" content="编辑后将会统一" placement="top-start">
                        <el-button type="text" size="mini">混合值编辑</el-button>
                    </el-tooltip>
                </span>
            </p>
            <target :label-width="labelWidth"></target>
        </div> -->
    <name-mix
      title="名称"
      :nameMsgList="nameMsgList"
      :nameMixShow.sync="nameMixShow"
      @setMixMsg="setMixMsg"
    ></name-mix>
    <status-mix
      title="状态"
      :statusMsgList="statusMsgList"
      :statusMixShow.sync="statusMixShow"
      @setMixMsg="setMixMsg"
    ></status-mix>
    <locals-mix
      :localsMixShow.sync="localsMixShow"
      :localsMsgList="localsMsgList"
      @setMixMsg="setMixMsg"
    ></locals-mix>
    <sex-mix
      title="性别"
      :sexMixShow.sync="sexMixShow"
      :sexMsgList="sexMsgList"
      @setMixMsg="setMixMsg"
    ></sex-mix>
    <age-mix
      title="年龄"
      :ageMixShow.sync="ageMixShow"
      :ageMsgList="ageMsgList"
      @setMixMsg="setMixMsg"
      :ageLocation="ageLocation"
    ></age-mix>
    <schedule-mix
      :scheduleMixShow.sync="scheduleMixShow"
      :scheduleMsgList="scheduleMsgList"
      @setMixMsg="setMixMsg"
      :copy="copy"
    ></schedule-mix>
    <budget-mix
      title="预算"
      :budgetMixShow.sync="budgetMixShow"
      :budgetMsgList="budgetMsgList"
      @setMixMsg="setMixMsg"
      :copy="copy"
      level="adset"
    ></budget-mix>
    <!-- 命名规则 -->
    <name-config
      :nameConfigShow="nameConfigShow"
      @close="close"
      :dictsInit="dictsInit"
      :fbAccountId="fbAccountId"
      :accountSet="accountSet"
      @setName="setName"
    ></name-config>

    <div
      class="audienceCover"
      v-if="statusData"
      v-loading="loadingAudience"
    >
      <div
        class="freshCon"
        @click="freshCon = false"
        v-show="freshCon"
        v-loading="loadingAudience"
      >
        <p>预估覆盖受众</p>
        <div
          class="num"
          v-if="statusData"
        >
          {{ usersWithUnit(statusData.estimate_mau || statusData.estimate_dau || 0) }}
        </div>
        <p><i class="el-icon-caret-left"></i></p>
      </div>
      <audience-previews
        v-show="!freshCon"
        @close="freshCon = true"
        :edit="true"
        :statusData="statusData"
        :content="JSON.stringify(listData.filter((v) => v.id == currentSelect[0])[0].targeting[0])"
        :accountId="accountId"
      ></audience-previews>
    </div>
  </div>
</template>
<script>
import location from './location';
import sex from './sex';
import locals from './locals';
import age from './age';
import flexibleSpec from './flexibleSpec';
import connection from './connection';
import schedule from './schedule';
import target from './target';
import localsMix from './localsMix';
import sexMix from './sexMix';
import ageMix from './ageMix';
import scheduleMix from './scheduleMix';
import name from '../campaign/name';
import nameMix from '../campaign/nameMix';
import status from '../campaign/status';
import pixel from './pixel';
import statusMix from '../campaign/statusMix';
import budget from '../campaign/budget';
import nameConfig from '../../../createAd/components/nameConfig';
import audiencePreviews from '../../../createAd/components/audiencePreviews';
import placement from '../../../createAd/components/placement/placement';
import advancedAudience from '@/views/adManagement/createAd/components/advancedAudience.vue';
import budgetMix from './budgetMix';
import bidAmount from './bidAmount';
import europeInput from './europeInput';
import { getSetsByAccounts, getDicts, accountPixel, deliveryEstimateAndSentence } from '@/api/creatAd.js';
import { namingByTargeting } from '@/api/adManagement.js';
export default {
  props: [
    'currentSelect',
    'baseData',
    'updateList',
    'pageList',
    'account',
    'copy',
    'targetUserId',
    'statusDataItem',
    'params',
    'type', //draft || null 是否是编辑草稿
  ],
  data() {
    return {
      labelWidth: '140px',
      localsMixShow: false,
      sexMixShow: false,
      ageMixShow: false,
      flexibleSpecShow: false,
      editlocations: false,
      relationsShow: false,
      scheduleShow: false,
      scheduleMixShow: false,
      locationList: [],
      localsList: [],
      sexList: [],
      ageList: [],
      flexibleSpecList: [],
      relationsList: [],
      scheduleList: [],
      locationMsg: {},
      localsMsg: [],
      sexMsg: '',
      ageMsg: {},
      flexibleSpecMsg: {},
      relationsMsg: {},
      scheduleMsg: {},
      listData: [],
      editList: [],
      editAdsetMsg: {},
      nameMixShow: false,
      statusMixShow: false,
      pixelMixShow: false,
      budgetMixShow: false,
      pixelMsg: {},
      nameMsg: '',
      statusMsg: '',
      nameList: [],
      statusList: [],
      pixelList: [],
      checked: true,
      nameConfigShow: false,
      accountSet: {},
      accountId: this.$store.getters.accountId,
      fbAccountId: this.$store.getters.fbAccountId,
      dictsInit: {},
      nameRules: '',
      accountPixelList: [],
      budgetList: [],
      budgetMsg: {},
      timer: null,
      canSet: true,
      loadPixel: false,
      initData: this.$store.getters.initData,
      statusData: {},
      freshCon: true,
      loadingAudience: false,
      bidAmountList: [],
      // bidAmountMsg: {},
      bidAmountShow: false,
      bidEuropeList: [],
      bidEuropeMsg: {},
    };
  },
  components: {
    location,
    sex,
    locals,
    age,
    flexibleSpec,
    connection,
    schedule,
    target,
    localsMix,
    sexMix,
    ageMix,
    scheduleMix,
    name,
    nameMix,
    status,
    statusMix,
    nameConfig,
    pixel,
    budget,
    budgetMix,
    placement,
    audiencePreviews,
    bidAmount,
    europeInput,
    advancedAudience,
  },
  computed: {
    budgetMsgList() {
      let list = [];
      this.currentSelect.map((v) => {
        this.listData.map((m) => {
          if (m.id == v) {
            let budgetObj = {};
            // 主页
            budgetObj.name = m.name || '';
            budgetObj.id = m.id;
            budgetObj.is_cbo = m.is_cbo;
            budgetObj.lifetime_budget = m.lifetime_budget;
            budgetObj.daily_budget = m.daily_budget;
            list.push(budgetObj);
          }
        });
      });
      return list;
    },
    nameMsgList() {
      let list = [];
      this.currentSelect.map((v) => {
        this.listData.map((m) => {
          if (m.id == v) {
            let nameObj = {};
            // 主页
            nameObj.name = m.name || '';
            nameObj.id = m.id;
            list.push(nameObj);
          }
        });
      });
      return list;
    },
    statusMsgList() {
      let list = [];
      this.currentSelect.map((v) => {
        this.listData.map((m) => {
          if (m.id == v) {
            let statusObj = {};
            // 主页
            statusObj.status = m.status || '';
            statusObj.id = m.id;
            statusObj.name = m.name;
            list.push(statusObj);
          }
        });
      });
      return list;
    },
    ageLocation() {
      let list = [];
      this.currentSelect.map((v) => {
        this.listData.map((m) => {
          if (m.id == v) {
            let obj = {};
            obj.id = m.id;
            obj.geo_locations = m.targeting[0].geo_locations;
            list.push(obj);
          }
        });
      });
      return list;
    },
    ageMsgList() {
      let list = [];
      this.currentSelect.map((v) => {
        this.listData.map((m) => {
          if (m.id == v) {
            let obj = {};
            obj.name = m.name;
            obj.id = m.id;
            obj.age_min = m.targeting[0].age_min;
            obj.age_max = m.targeting[0].age_max;
            list.push(obj);
          }
        });
      });
      return list;
    },
    sexMsgList() {
      let list = [];
      this.currentSelect.map((v) => {
        this.listData.map((m) => {
          if (m.id == v) {
            let obj = {};
            obj.name = m.name;
            obj.id = m.id;
            obj.genders = m.targeting[0].genders;
            list.push(obj);
          }
        });
      });
      return list;
    },
    localsMsgList() {
      let list = [];
      this.currentSelect.map((v) => {
        this.listData.map((m) => {
          if (m.id == v) {
            let obj = {};
            obj.name = m.name;
            obj.id = m.id;
            obj.locales = m.targeting[0].locales;
            list.push(obj);
          }
        });
      });
      return list;
    },
    scheduleMsgList() {
      let list = [];
      this.currentSelect.map((v) => {
        this.listData.map((m) => {
          if (m.id == v) {
            let obj = {};
            obj.name = m.name;
            obj.id = m.id;
            obj.start_date = m.start_date;
            obj.end_date = m.end_date;
            list.push(obj);
          }
        });
      });
      return list;
    },
    placementMsg: {
      get() {
        if (this.currentSelect.length !== 1) {
          return {};
        }
        let obj = {};
        this.currentSelect.map((v) => {
          this.listData.map((m) => {
            if (m.id == v) {
              obj = {
                device_platforms: m.targeting[0].device_platforms || [],
                user_os: m.targeting[0].user_os || ['All'],
                user_device: m.targeting[0].user_device || [],
                facebook_positions: m.targeting[0].facebook_positions || [],
                instagram_positions: m.targeting[0].instagram_positions || [],
                audience_network_positions: m.targeting[0].audience_network_positions || [],
                messenger_positions: m.targeting[0].messenger_positions || [],
              };
            }
          });
        });
        return obj;
      },
    },
    advancedMsg: {
      get() {
        if (this.currentSelect.length !== 1) {
          return {};
        }
        let obj = {};
        this.currentSelect.map((v) => {
          this.listData.map((m) => {
            if (m.id == v) {
              obj = {
                advantage_audience: m.targeting[0].targeting_automation?.advantage_audience || 0,
              };
            }
          });
        });
        return obj.advantage_audience;
      },
      set(val) {
        this.currentSelect.map((v) => {
          this.listData.map((k, i) => {
            if (v == k.id) {
              //console.log(k);
              k.targeting[0].targeting_automation.advantage_audience = val;
              this.setEditAdsetMsg(v, 'advantage_audience', val);
              this.$set(this.listData, i, k);
            }
          });
        });
        this.listData = JSON.parse(JSON.stringify(this.listData));
        this.setList();
      },
    },
    cboList() {
      let list = [];
      this.currentSelect.map((v) => {
        this.listData.map((m) => {
          if (m.id == v) {
            list.push(m.is_cbo);
          }
        });
      });
      return list;
    },
    bidStrategyList() {
      let list = [];
      this.currentSelect.map((v) => {
        this.listData.map((m) => {
          if (m.id == v) {
            list.push({
              bid_strategy: m.bid_strategy,
              optimization_goal: m.optimization_goal,
              smart_promotion_type: m.smart_promotion_type,
            });
          }
        });
      });
      return list;
    },
    bidAmountMsg() {
      return this.isNotMix(this.bidAmountList)
        ? this.bidAmountList[0]
        : {
            is_cbo: false,
            bid_amount: '1',
            attribution_spec: 'click_1',
            billing_event: 'IMPRESSIONS',
            bid_strategy: this.bidAmountList[0].bid_strategy,
            optimization_goal: this.bidAmountList[0].optimization_goal,
            roas_average_floor: null,
            objective: 'OUTCOME_SALES',
            business: '网站',
            smart_promotion_type: this.bidAmountList[0].smart_promotion_type,
          };
    },
  },
  methods: {
    nameConfig() {
      this.nameConfigShow = true;
    },
    close(v) {
      this[v] = false;
    },
    // setName(v){
    //     this.nameRules = v.adset_name;
    // },
    // 根据受众词自动命名
    setName() {
      if (!this.checked) return false;
      if (!this.canSet) return false;
      let obj = {};
      let list = JSON.parse(JSON.stringify(this.listData));
      this.currentSelect.map((v) => {
        list.map((k, i) => {
          if (k.id == v) {
            k.targeting[0].genders = k.targeting[0].genders
              ? k.targeting[0].genders === 'all'
                ? ['M', 'F']
                : [k.targeting[0].genders]
              : null;
            k.targeting[0].exclusions =
              k.targeting[0].exclusions && k.targeting[0].exclusions.length ? k.targeting[0].exclusions[0] : null;
            obj[v] = k.targeting[0];
          }
        });
      });
      namingByTargeting({ targetings: obj, naming_rule: '{国家代码}_{性别缩写}_{年龄}_{受众项}' }).then((res) => {
        // 获取数据
        for (let key in res.data) {
          this.$emit('setBaseName', 'adset', key, res.data[key]);
          this.currentSelect.map((v) => {
            this.listData.map((k, i) => {
              if (k.id == key) {
                k.name = res.data[key];
                this.$set(this.listData, i, k);
              }
            });
          });
        }
        this.listData = JSON.parse(JSON.stringify(this.listData));
        //console.log(this.listData);
        this.setList();
        this.setNameMsg();
        this.$forceUpdate();
      });
    },
    // 获取广告账户默认设置
    getAccountSet(v) {
      // //console.log('getAccountSet(v)',this.fbAccountId);
      getSetsByAccounts({ accounts: [v] }).then((res) => {
        // //console.log('getSetsByAccounts',res);
        this.accountSet = res.data.list[0];
        this.nameRules = res.data.list[0].adset_name;
        // this.splitPreviews();
      });
    },
    getDictsInit() {
      getDicts().then((res) => {
        // //console.log(res);
        this.dictsInit = res.data;
      });
    },
    setFelx() {
      this.flexibleSpecShow = true;
    },
    setList() {
      let locationList = [];
      let localsList = [];
      let sexList = [];
      let ageList = [];
      let flexibleSpecList = [];
      let relationsList = [];
      let scheduleList = [];
      let nameList = [];
      let statusList = [];
      let pixelList = [];
      let budgetList = [];
      let bidAmountList = [];
      let bidEuropeList = [];
      this.currentSelect.map((v) => {
        this.listData.map((m) => {
          if (m.id == v) {
            console.log(m);
            let locationObj = {};
            let localsObj = {};
            let sexObj = {};
            let ageObj = {};
            let flexibleSpecObj = {};
            let relationsObj = {};
            let scheduleObj = {};
            let bidAmountObj = {};
            let bidEuropeObj = {};
            // 名称
            nameList.push(m.name);
            // 状态
            statusList.push(m.status);
            // 预算
            budgetList.push({
              is_cbo: m.is_cbo,
              lifetime_budget: m.lifetime_budget,
              daily_budget: m.daily_budget,
              daily_min_spend_target: m.daily_min_spend_target / 100,
              daily_spend_cap: m.daily_spend_cap / 100,
              lifetime_min_spend_target: m.lifetime_min_spend_target / 100,
              lifetime_spend_cap: m.lifetime_spend_cap / 100,
              daily_budget_campaign: m.daily_budget_campaign,
              lifetime_budget_campaign: m.lifetime_budget_campaign,
            });
            // 像素,触发事件
            pixelList.push(m.promoted_object);
            // 地区
            locationObj.geo_locations = m.targeting[0].geo_locations || [];
            locationObj.geo_locations_region = m.targeting[0].geo_locations_region || [];
            locationObj.geo_locations_city = m.targeting[0].geo_locations_city || [];
            locationObj.excluded_geo_locations = m.targeting[0].excluded_geo_locations || [];
            locationObj.excluded_geo_locations_regions = m.targeting[0].excluded_geo_locations_regions || [];
            locationObj.excluded_geo_locations_cities = m.targeting[0].excluded_geo_locations_cities || [];
            locationObj.excluded_geo_locations_subcities = m.targeting[0].excluded_geo_locations_subcities || [];
            locationObj.excluded_geo_locations_places = m.targeting[0].excluded_geo_locations_places || [];
            locationObj.excluded_geo_locations_place_radius = m.targeting[0].excluded_geo_locations_place_radius || '';
            locationObj.location_types = m.targeting[0].location_types || 'all';
            locationList.push(locationObj);
            // 语言
            localsList.push(m.targeting[0].locales);
            // 性别
            sexList.push(m.targeting[0].genders);
            // 年龄
            ageObj.age_min = m.targeting[0].age_min;
            ageObj.age_max = m.targeting[0].age_max;
            ageList.push(ageObj);
            // 兴趣词
            flexibleSpecObj.targeting_optimization = m.targeting[0].targeting_optimization || 'expansion_all';
            flexibleSpecObj.flexible_spec = m.targeting[0].flexible_spec ? [...m.targeting[0].flexible_spec] : [];
            flexibleSpecObj.notPeople = m.targeting[0].exclusions ? [...m.targeting[0].exclusions] : [];
            flexibleSpecList.push(flexibleSpecObj);
            // 关系
            relationsObj.connections = m.targeting[0].connections || [];
            relationsObj.excluded_connections = m.targeting[0].excluded_connections || [];
            relationsObj.friends_of_connections = m.targeting[0].friends_of_connections || [];
            relationsList.push(relationsObj);
            // 排期
            scheduleObj.start_date = m.start_date;
            scheduleObj.end_date = m.end_date || '';
            scheduleList.push(scheduleObj);
            // 竞价
            bidAmountObj.is_cbo = m.is_cbo;
            bidAmountObj.bid_amount = m.bid_amount;
            bidAmountObj.attribution_spec = m.attribution_spec;
            bidAmountObj.billing_event = m.billing_event;
            bidAmountObj.bid_strategy = m.bid_strategy;
            bidAmountObj.optimization_goal = m.optimization_goal;
            bidAmountObj.objective = m.campaignObjective;
            bidAmountObj.business = m.business || '网站';
            bidAmountObj.smart_promotion_type = m.smart_promotion_type;
            bidAmountObj.roas_average_floor = m.roas_average_floor;
            bidAmountList.push(bidAmountObj);
            // 欧盟
            bidEuropeObj.dsa_payor = m.dsa_payor;
            bidEuropeObj.dsa_beneficiary = m.dsa_beneficiary;
            bidEuropeList.push(bidEuropeObj);
          }
        });
        this.locationList = locationList;
        this.localsList = localsList;
        this.sexList = sexList;
        this.ageList = ageList;
        this.flexibleSpecList = flexibleSpecList;
        this.relationsList = relationsList;
        this.scheduleList = scheduleList;
        this.nameList = nameList;
        this.statusList = statusList;
        this.pixelList = pixelList;
        this.budgetList = budgetList;
        this.bidAmountList = bidAmountList;
        this.bidEuropeList = bidEuropeList;
      });
    },
    setEditAdsetMsg(id, key, val) {
      this.$nextTick(() => {
        console.log(this.listData, 'lsisdsfs');
        this.$emit('setData', this.listData);
      });
      // this.$emit('setData',JSON.parse(JSON.stringify(this.listData)))
      // if(key == 'locations'){
      //     //console.log('1111111111');
      // }
      // if(key == 'locations' || key == 'age' || key == 'flex_spec' || key == 'rations' || key == 'schedule'){
      //     if(key == 'locations'){
      //         delete val['peopleGroup']
      //     }
      //     for(let a in val){
      //         this.editAdsetMsg[id] = this.editAdsetMsg[id]?this.editAdsetMsg[id]:{};
      //         this.$set(this.editAdsetMsg[id],a,val[a])
      //     }
      // }else{
      //     this.editAdsetMsg[id][key] = val;
      // }
    },
    setBaseMsg() {
      this.setLocations();
      this.setLocals();
      this.setSex();
      this.setAge();
      this.setflexibleSpecMsg();
      this.setReations();
      this.setSchedule();
      this.setNameMsg();
      this.setStatusMsg();
      this.setPixelMsg();
      this.setBudgetMsg();
      // this.setBidAmountMsg();
      this.setBidEuropeMsg();
    },
    setBaseSetTime() {
      this.timer = null;
      this.timer = setTimeout(function () {
        this.canSet = true;
      }, 1000);
    },
    editBaseList(type, val) {
      // 修改地区
      // if(type == 'locations' && JSON.stringify(val) == JSON.stringify(this.locationMsg)) return;
      this.currentSelect.map((v) => {
        this.listData.map((k, i) => {
          if (v == k.id) {
            if (type == 'locations') {
              k.targeting[0].geo_locations = val.geo_locations;
              k.targeting[0].geo_locations_region = val.geo_locations_region;
              k.targeting[0].geo_locations_city = val.geo_locations_city;
              k.targeting[0].excluded_geo_locations = val.excluded_geo_locations;
              k.targeting[0].location_types = val.location_types;
              k.targeting[0].excluded_geo_locations_regions = val.excluded_geo_locations_regions;
              k.targeting[0].excluded_geo_locations_cities = val.excluded_geo_locations_cities;
              k.targeting[0].excluded_geo_locations_subcities = val.excluded_geo_locations_subcities;
              k.targeting[0].excluded_geo_locations_places = val.excluded_geo_locations_places;
              k.targeting[0].excluded_geo_locations_place_radius = val.excluded_geo_locations_place_radius;
              this.setEditAdsetMsg(v, 'locations', val);
            }
            this.getLocations(val);
            if (type == 'locales') {
              k.targeting[0].locales = val;
            }
            this.getLocals(val);
            this.$set(this.listData, i, k);
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      this.setList();
    },
    // 判断是否是混合值
    isNotMix(list) {
      return !list.some((v) => {
        return JSON.stringify(v) != JSON.stringify(list[0]);
      });
    },
    // 编辑混合值
    editMix(v) {
      this[v] = true;
    },
    // 设置locationMsg
    setLocations() {
      this.locationMsg = this.isNotMix(this.locationList)
        ? { ...this.locationList[0] }
        : {
            geo_locations: [],
            geo_locations_region: [],
            geo_locations_city: [],
            excluded_geo_locations: [],
            excluded_geo_locations_regions: [],
            location_types: 'all',
          };
    },
    getLocations(val) {
      this.currentSelect.map((v) => {
        this.listData.map((k, i) => {
          if (v == k.id) {
            //console.log(k);
            k.targeting[0].geo_locations = val.geo_locations;
            k.targeting[0].geo_locations_region = val.geo_locations_region;
            k.targeting[0].geo_locations_city = val.geo_locations_city;
            k.targeting[0].excluded_geo_locations = val.excluded_geo_locations;
            k.targeting[0].excluded_geo_locations_regions = val.excluded_geo_locations_regions;
            k.targeting[0].excluded_geo_locations_cities = val.excluded_geo_locations_cities;
            k.targeting[0].excluded_geo_locations_subcities = val.excluded_geo_locations_subcities;
            k.targeting[0].excluded_geo_locations_places = val.excluded_geo_locations_places;
            k.targeting[0].excluded_geo_locations_place_radius = val.excluded_geo_locations_place_radius;
            k.targeting[0].location_types = val.location_types;
            this.setEditAdsetMsg(v, 'locations', val);
            this.$set(this.listData, i, k);
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      //console.log(this.listData);
      this.setName();
      this.setList();
    },
    setLocals() {
      this.localsMsg = this.isNotMix(this.localsList) && this.localsList[0] ? [...this.localsList[0]] : [];
    },
    getLocals(val) {
      // if(JSON.stringify(val) == JSON.stringify(this.localsMsg)) return;

      this.currentSelect.map((v) => {
        this.listData.map((k, i) => {
          if (v == k.id) {
            k.targeting[0].locales = val;
            this.setEditAdsetMsg(v, 'locales', val);
            this.$set(this.listData, i, k);
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      this.setName();
      this.setList();
    },
    setSex() {
      this.sexMsg = this.isNotMix(this.sexList) ? this.sexList[0] : 'all';
    },
    getSex(val) {
      //console.log(val);
      // if(val == this.sexMsg) return;

      this.currentSelect.map((v) => {
        this.listData.map((k, i) => {
          if (v == k.id) {
            k.targeting[0].genders = val;
            this.setEditAdsetMsg(v, 'sex', val);
            this.$set(this.listData, i, k);
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      this.setName();
      this.setList();
    },
    setAge() {
      this.ageMsg = this.isNotMix(this.ageList) ? this.ageList[0] : { age_min: '', age_max: '' };
    },
    getAge(val) {
      // if(JSON.stringify(val) == JSON.stringify(this.ageMsg)) return;

      this.currentSelect.map((v) => {
        this.listData.map((k, i) => {
          if (v == k.id) {
            k.targeting[0].age_min = val.age_min;
            k.targeting[0].age_max = val.age_max;
            this.setEditAdsetMsg(v, 'age', val);
            this.$set(this.listData, i, k);
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      this.setName();
      this.setList();
    },
    setflexibleSpecMsg() {
      this.flexibleSpecMsg = this.isNotMix(this.flexibleSpecList)
        ? { ...this.flexibleSpecList[0] }
        : { flexible_spec: [], notPeople: [], targeting_optimization: 'expansion_all' };
    },
    setFexible(val) {
      // if(JSON.stringify(val) == JSON.stringify(this.flexibleSpecMsg)){
      //     return
      // }

      this.currentSelect.map((v) => {
        this.listData.map((k, i) => {
          if (v == k.id) {
            k.targeting[0].flexible_spec = val.flexible_spec;
            k.targeting[0].exclusions = val.notPeople;
            k.targeting[0].targeting_optimization = val.targeting_optimization;
            this.setEditAdsetMsg(v, 'flex_spec', val);
            this.$set(this.listData, i, k);
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      this.setName();
      this.setList();
    },
    setReations() {
      this.relationsMsg = this.isNotMix(this.relationsList)
        ? { ...this.relationsList[0] }
        : { connections: [], excluded_connections: [], friends_of_connections: [] };
    },
    setReationsMsg(val) {
      // if(JSON.stringify(val) == JSON.stringify(this.relationsMsg)) return;
      this.currentSelect.map((v) => {
        this.listData.map((k, i) => {
          if (v == k.id) {
            k.targeting[0].connections = val.connections;
            k.targeting[0].excluded_connections = val.excluded_connections;
            k.targeting[0].friends_of_connections = val.friends_of_connections;
            this.setEditAdsetMsg(v, 'rations', val);
            this.$set(this.listData, i, k);
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      this.setList();
    },
    setSchedule() {
      this.scheduleMsg = this.isNotMix(this.scheduleList)
        ? { ...this.scheduleList[0] }
        : { connections: [], excluded_connections: [], friends_of_connections: [] };
    },
    getSchedule(val) {
      // if(JSON.stringify(val) == JSON.stringify(this.scheduleMsg)) return;
      this.currentSelect.map((v) => {
        this.listData.map((k, i) => {
          if (v == k.id) {
            k.end_date = val.end_date;
            k.start_date = val.start_date;
            this.setEditAdsetMsg(v, 'schedule', val);
            this.$set(this.listData, i, k);
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      this.setList();
    },
    setNameMsg() {
      this.nameMsg = this.isNotMix(this.nameList) ? this.nameList[0] : '';
    },
    getNameMsg(val) {
      // if(JSON.stringify(val) == JSON.stringify(this.nameMsg)) return;
      this.currentSelect.map((v) => {
        this.$emit('setBaseName', 'adset', v, val);
        this.listData.map((k, i) => {
          if (v == k.id) {
            k.name = val;
            this.setEditAdsetMsg(v, 'name', val);
            this.$set(this.listData, i, k);
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      this.setList();
    },
    setStatusMsg() {
      this.statusMsg = this.isNotMix(this.statusList) ? this.statusList[0] : 'PAUSED';
    },
    getStatusMsg(val) {
      // if(JSON.stringify(val) == JSON.stringify(this.statusMsg)) return;
      this.currentSelect.map((v) => {
        this.listData.map((k, i) => {
          if (v == k.id) {
            k.status = val;
            this.setEditAdsetMsg(v, 'status', val);
            this.$set(this.listData, i, k);
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      this.setList();
      this.setStatusMsg();
    },
    setPixelMsg() {
      this.pixelMsg = this.isNotMix(this.pixelList)
        ? this.pixelList[0]
        : { pixel_id: '', page_id: null, custom_event_type: '' };
    },
    getPixelMsg(val) {
      // if(JSON.stringify(val) == JSON.stringify(this.pixelMsg)) return;
      this.currentSelect.map((v) => {
        this.listData.map((k, i) => {
          if (v == k.id) {
            k.promoted_object = { ...val };
            this.setEditAdsetMsg(v, 'pixel', val);
            this.$set(this.listData, i, k);
            //console.log(k);
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      this.setList();
    },
    setBudgetMsg() {
      this.budgetMsg = this.isNotMix(this.budgetList)
        ? this.budgetList[0]
        : { is_cbo: false, lifetime_budget: 0, daily_budget: 0 };
    },
    getBudgetMsg(val) {
      // if(JSON.stringify(val) == JSON.stringify(this.budgetMsg)) return;
      this.currentSelect.map((v) => {
        this.$emit('setBaseBudget', 'adset', v, val);
        this.listData.map((k, i) => {
          if (v == k.id) {
            k = { ...k, ...val };
            this.setEditAdsetMsg(v, 'budget', val);
            this.$set(this.listData, i, k);
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      this.setList();
      this.setBudgetMsg();
    },
    // setBidAmountMsg() {
    //   this.bidAmountMsg = this.isNotMix(this.bidAmountList)
    //     ? this.bidAmountList[0]
    //     : {
    //         is_cbo: false,
    //         bid_amount: '2001',
    //         attribution_spec: 'click_7_and_view_1',
    //         billing_event: 'IMPRESSIONS',
    //         bid_strategy: 'LOWEST_COST_WITH_BID_CAP',
    //         optimization_goal: 'OFFSITE_CONVERSIONS',
    //         roas_average_floor: null,
    //         objective:'OFFSITE_CONVERSIONS',
    //         business:'网站'
    //       };
    // },
    setBidEuropeMsg() {
      this.bidEuropeMsg = this.isNotMix(this.bidEuropeList) ? this.bidEuropeList[0] : {};
    },
    getBidAmountMsg(val) {
      this.currentSelect.map((v) => {
        this.listData.map((k, i) => {
          if (v == k.id) {
            k = { ...k, ...val };
            this.setEditAdsetMsg(v, 'bidAmount', val);
            this.$set(this.listData, i, k);
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      this.setList();
      let key = val.bid_strategy == 'LOWEST_COST_WITH_MIN_ROAS' ? 'roas_average_floor' : 'bid_amount';
      this.$emit('setBidStrategy', val.bid_strategy, val[key]);
      // this.setBidAmountMsg()
    },
    getBidEuropeMsg(val) {
      this.currentSelect.map((v) => {
        this.listData.map((k, i) => {
          if (v == k.id) {
            k = { ...k, ...val };
            this.setEditAdsetMsg(v, 'bidEuropeMsg', val);
            this.$set(this.listData, i, k);
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      this.setList();
    },
    // 接收混合值
    setMixMsg(type, list) {
      if (!list) return;
      list.map((v) => {
        if (type == 'name') {
          this.$emit('setBaseName', 'adset', v.id, v.name);
        }
        this.listData.map((k, i) => {
          if (v.id == k.id) {
            if (type == 'sex') {
              k.targeting[0].genders = v.genders;
            }
            if (type == 'locales') {
              k.targeting[0].locales = v.locales;
            }
            if (type == 'age') {
              k.targeting[0].age_min = v.age_min;
              k.targeting[0].age_max = v.age_max;
            }
            if (type == 'schedule') {
              k.end_date = v.end_date;
              k.start_date = v.start_date;
            }
            if (type == 'name') {
              k.name = v.name;
              // this.$emit('setBaseName','adset',v,val)
            }
            if (type == 'status') {
              k.status = v.status;
            }
            if (type == 'budget') {
              k.daily_budget = v.daily_budget;
              k.lifetime_budget = v.lifetime_budget;
            }
            this.setEditAdsetMsg(v, type, v);
          }

          this.$set(this.listData, i, k);
        });
      });
      this.setList();
      // this.setBaseMsg()
      this.setLocals();
      this.setSex();
      this.setAge();
      this.setSchedule();
      this.setBudgetMsg();
      // this.setBidAmountMsg();
      if (type == 'name') this.setNameMsg();
      if (type == 'status') this.setStatusMsg();
      this.$forceUpdate();
    },
    setBase(type) {
      this.currentSelect.map((v) => {
        let list = this.baseData;
        let baseMsg = list.filter((k) => k.id == v) ? list.filter((k) => k.id == v)[0] : null;
        if (!baseMsg) {
          return;
        }
        let msg = JSON.parse(JSON.stringify(baseMsg));
        this.listData.map((m, i) => {
          if (m.id == v) {
            if (type == 'locations') {
              m.targeting[0].geo_locations = msg.targeting[0].geo_locations || [];
              m.targeting[0].geo_locations_region = msg.targeting[0].geo_locations_region || [];
              m.targeting[0].geo_locations_city = msg.targeting[0].geo_locations_city || [];
              m.targeting[0].excluded_geo_locations = msg.targeting[0].excluded_geo_locations || [];
              m.targeting[0].excluded_geo_locations_regions = msg.targeting[0].excluded_geo_locations_regions || [];
              m.targeting[0].location_types = msg.targeting[0].location_types || 'all';
              this.editlocations = false;
            }
            if (type == 'flexible') {
              m.targeting[0].targeting_optimization = msg.targeting[0].targeting_optimization || 'expand_all';
              m.targeting[0].flexible_spec = msg.targeting[0].flexible_spec || [];
              m.targeting[0].exclusions = msg.targeting[0].exclusions || {};
              this.setflexibleSpecMsg();
              this.flexibleSpecShow = false;
            }
            if (type == 'relations') {
              m.targeting[0].connections = msg.targeting[0].connections || [];
              m.targeting[0].excluded_connections = msg.targeting[0].excluded_connections || [];
              m.targeting[0].friends_of_connections = msg.targeting[0].friends_of_connections || [];
              this.setReations();
              this.relationsShow = false;
            }
            if (type == 'pixel') {
              m.promoted_object = { ...msg.promoted_object };
              this.pixelMixShow = false;
            }
            if (type == 'bidAmount') {
              m.bid_amount = msg.bid_amount;
              m.attribution_spec = msg.attribution_spec;
              m.billing_event = msg.billing_event;
              m.bid_strategy = msg.bid_strategy;
              m.optimization_goal = msg.optimization_goal;
              m.roas_average_floor = msg.roas_average_floor;
            }

            // this.listData = JSON.stringify(JSON.parse(this.listData))
          }
          this.$set(this.listData, i, JSON.parse(JSON.stringify(m)));
        });
      });
      //
      this.setList();
      if (type == 'locations') {
        this.setLocations();
      }
      if (type == 'flexible') {
        this.setflexibleSpecMsg();
      }
      if (type == 'relations') {
        this.setReations();
      }
      if (type == 'pixel') {
        this.setPixelMsg();
      }
    },
    getPixelList(v) {
      this.loadPixel = true;
      accountPixel({ accountId: this.account, force: v }, this, this.currentRowNum || this.targetUserId).then((res) => {
        this.loadPixel = false;
        this.accountPixelList = res.data;
        if (v) {
          this.$message('更新成功');
        }
      });
    },
    // 获取版位
    getPlacemment(val) {
      //console.log(val);
      this.currentSelect.map((v) => {
        this.listData.map((k, i) => {
          if (v == k.id) {
            k.targeting[0] = { ...k.targeting[0], ...val };
            //console.log(k);
            this.$set(this.listData, i, k);
            this.setEditAdsetMsg(v, 'placement', val);
          }
        });
      });
    },
    // 受众人数
    usersWithUnit(users) {
      if (users < 10e3) return users;
      if (users < 10e7) return (users / 10e3).toFixed(0) + '万';
      return (users / 10e7).toFixed(0) + '亿';
    },
    // 获取受众信息
    previewAudience(id) {
      let data = this.updateList.filter((v) => v.id == id)[0];
      if (
        data.targeting[0].excluded_geo_locations_places &&
        data.targeting[0].excluded_geo_locations_places.length &&
        !data.targeting[0].excluded_geo_locations_place_radius
      ) {
        this.statusData = null;
        return false;
      }
      if (
        (data.targeting[0].geo_locations && data.targeting[0].geo_locations.length) ||
        (data.targeting[0].geo_locations_region && data.targeting[0].geo_locations_region.length) ||
        (data.targeting[0].geo_locations_city && data.targeting[0].geo_locations_city.length)
      ) {
        let par = JSON.parse(JSON.stringify(data));
        let params = {
          objective: par.campaignObjective || data.ads[0].objective,
          promotedPageId: par.promoted_object?.page_id || null,
          fbPixelId: par.promoted_object?.pixel_id || par.pixelId,
          customEventType: par.promoted_object?.custom_event_type || 'PURCHASE',
          invaild_insterest: this.statusData ? this.statusData.invaild_insterest : [],
        };
        let targeting = par.targeting[0];
        targeting.genders = targeting.genders === 'all' ? ['M', 'F'] : targeting.genders ? [targeting.genders] : null;
        targeting.exclusions = targeting.exclusions && targeting.exclusions.length ? targeting.exclusions[0] : null;
        params.targeting = targeting;
        params.accountId = this.accountId;
        this.loadingAudience = true;
        // this.$showLoading();
        deliveryEstimateAndSentence(params)
          .then((res) => {
            this.loadingAudience = false;
            if (res.code == 0) {
              this.statusData = res.data;
            }
          })
          .finally(() => {
            // this.$hideLoading();
          });
      } else {
        this.statusData = null;
      }
    },
    setBudget(key, val) {
      this.$emit('setBudget', key, val);
    },
    setBid(v) {
      this.$emit('setBid', v);
      this.$emit('setBidStrategy', v);
    },
    setRoas(v) {
      this.$emit('setRoas', v);
    },
  },
  watch: {
    currentSelect: {
      handler() {
        this.canSet = false;
        if (this.currentSelect.length == 1) {
          this.previewAudience(this.currentSelect[0]);
        }
        this.setList();
        this.setBaseMsg();
        this.$nextTick(function () {
          this.canSet = true;
        });
      },
      deep: true,
      immediate: true,
    },
    updateList: {
      handler() {
        // this.listData = JSON.parse(JSON.stringify(this.baseData))
        // this.setList();
        // this.setBaseMsg()
        // this.$emit('setData',JSON.parse(JSON.stringify(this.baseData)))
        console.log(this.updateList);
        this.listData = JSON.parse(JSON.stringify(this.updateList));
        if (this.currentSelect.length == 1) {
          this.previewAudience(this.currentSelect[0]);
        }
      },
      deep: true,
      immediate: true,
    },
    account: {
      handler() {
        this.getPixelList();
      },
      immediate: true,
    },
    statusData: {
      deep: true,
      immediate: true,
      handler(val) {
        this.$emit('update:statusDataItem', val);
      },
    },
  },
  created() {
    // //console.log('11111111');
    this.listData = JSON.parse(JSON.stringify(this.updateList));
    this.setList();
    this.setBaseMsg();
    this.getAccountSet(this.accountId);
    this.getDictsInit();
  },
};
</script>
<style lang="scss" scope>
.audienceCover {
  position: fixed;
  right: 0;
  top: 30%;
  // padding: 20px;
  border: 1px solid rgb(200, 224, 255);
  cursor: pointer;
  background: #fff;
  border-radius: 6px;
  max-width: 260px;
  background-color: #f8fbff;
  // box-sizing: border-box;
  .freshCon {
    padding: 20px;
  }

  .num {
    color: #f55949;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    line-height: 2;
  }

  p {
    font-size: 12px;
    color: #666;
    text-align: center;
  }
}
.targetingItem {
  .baseName {
    display: flex;
    align-items: center;
    .lebel {
      flex: 140px 0 0;
      text-align: right;
      padding-right: 12px;
    }
  }
}
.mixName {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mixBtn {
  line-height: 26px;
}
</style>
